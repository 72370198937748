<template>
  <div class="c-app">
    <div class="fix-header px-4">
      <div class="row m-0 p-0 form-inline w-100 h-100">
        <div class="col m-0 p-0">
          <div class="row form-inline">
            <div class="col fs-16 bolder"></div>
          </div>
          <div class="row fs-12" style="visibility: hidden">
            <div class="col-auto form-inline">
              <CIcon name="cil-phone" class="mr-1" />
              {{ $t("Call") }}
            </div>
            <div class="col-auto form-inline">
              <CIcon name="cib-whatsapp" class="mr-1" />
              {{ $t("Whats app") }}
            </div>
          </div>
        </div>
        <div
          class="col-auto m-0 p-0 form-inline fs-24"
          style="display: inline-flex; justify-content: right"
        >
          <img src="/ic_logo.png" alt="Seelfa" height="40" />
          Seelfa
        </div>
      </div>
    </div>
    <div class="scroll-block">
      <div class="container">
        <div class="row">
        <div class="success-div"> 
          <h2>Payment Success !</h2>
          <p>Your Transaction ID #{{ transactionID }}</p>
          <b-button onClick='window.history.back()' class="button">Back to App</b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ThankYou",
  components: {},
  data() {
    return {
      transactionID: this.$route.params.transactionID,
    };
  },
  created() {},
  methods: {},
};
</script>
<style scoped>
.col.separater {
  display: inline-flex;
  margin: 0 auto;
  padding: 0;
  max-width: 1px;
  background-color: rgba(144, 145, 146, 0.3);
}
.c-app {
  display: flex;
  margin: 0 auto;
  max-width: 480px;
  width: 100%;
}
.fix-header {
  display: flex;
  position: fixed;
  background-color: #4daf4e;
  width: 100%;
  max-width: 480px;
  height: 90px;
  color: white;
}
.scroll-block {
  margin: 90px auto 0 auto;
  position: fixed;
  width: 100%;
  max-width: 480px;
  height: calc(100vh - 92px);
  overflow-x: hidden;
  overflow-y: auto;
  background-color: white;
}
.success-div{
  padding: 50px 30px;
  text-align: center;
  margin:0 auto;
}
.button {
  background-color: #2eb85c;
  color: #fff;
  box-shadow: none;
  border: none;
  padding: 7px 15px;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-top: 20px;
}
</style>